define("ember-place-autocomplete/services/google-place-autocomplete", ["exports", "@ember/service", "rsvp", "@ember/utils"], function (_exports, _service, _rsvp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = _service.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var google = this.google || (window ? window.google : null);
      if (google && document) {
        var googlePlaces = google.maps.places;
        var autocompleteService = new googlePlaces.AutocompleteService();
        var placesService = new googlePlaces.PlacesService(document.createElement('div'));
        var sessionToken = new googlePlaces.AutocompleteSessionToken();
        this.setProperties({
          autocompleteService: autocompleteService,
          google: google,
          sessionToken: sessionToken,
          placesService: placesService
        });
      }
    },
    getPlacePredictions: function getPlacePredictions(properties) {
      var _this = this;
      if (!properties.hasOwnProperty('input')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if ((0, _utils.isBlank)(properties.input)) {
        return _rsvp.Promise.resolve([]);
      }
      properties.sessionToken = this.sessionToken;
      return new _rsvp.Promise(function (resolve) {
        _this.autocompleteService.getPlacePredictions(properties, _this._googleResponseCallback.bind(_this, [resolve], []));
      });
    },
    getQueryPredictions: function getQueryPredictions(properties) {
      var _this2 = this;
      if (!properties.hasOwnProperty('input')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if ((0, _utils.isBlank)(properties.input)) {
        return _rsvp.Promise.resolve([]);
      }
      return new _rsvp.Promise(function (resolve) {
        _this2.autocompleteService.getQueryPredictions(properties, _this2._googleResponseCallback.bind(_this2, [resolve], []));
      });
    },
    getDetails: function getDetails(request) {
      var _this3 = this;
      request.sessionToken = this.sessionToken;
      if (!request.hasOwnProperty('fields') && !request.hasOwnProperty('placeId')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] getDetails needs the placeId and fields as properties of the request params'));
      }
      this.updateSessionToken();
      return new _rsvp.Promise(function (resolve) {
        _this3.placesService.getDetails(request, _this3._googleResponseCallback.bind(_this3, [resolve], {}));
      });
    },
    _googleResponseCallback: function _googleResponseCallback(promiseCallbacks, failResponseReturnValue, requestResult, status) {
      var google = this.google || (window ? window.google : null);
      var _promiseCallbacks = _slicedToArray(promiseCallbacks, 1),
        resolve = _promiseCallbacks[0];
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return resolve(requestResult);
      }
      return resolve(failResponseReturnValue);
    },
    updateSessionToken: function updateSessionToken() {
      var googlePlaces = this.google.maps.places;
      this.set('sessionToken', new googlePlaces.AutocompleteSessionToken());
    }
  });
});