define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["cs", {
    "Acknowledgement received": "Přijmout potvrzení",
    "Active signal": "Přiřazené výtahy",
    "Campaigns": "Kampaně",
    "Companies": "Společnosti",
    "Devices": "Zařízení",
    "Door Number": "Číslo dveří",
    "Door Status": "Číslo stav",
    "Floor": "Poschodí",
    "Groups": "Skupiny",
    "Licenses": "Licence",
    "MB": "MB",
    "Media": "Média",
    "Status": "Stav",
    "This value is already used.": "Tato hodnota se již používá.",
    "Type": "Typ",
    "Users": "Uživatel",
    "acknowledgement.received": "Přijmout potvrzení",
    "act.as.admin": "Působit jako správce",
    "add.campaign": "Přidat kampaň",
    "api.unreachable": "API je nedostupné.",
    "area": {
      "bottom": "spodní část plochy",
      "center": "Střední oblast",
      "left": "Oblast vlevo",
      "main": "Hlavní oblast",
      "right": "Oblast vpravo",
      "top": "horní část oblasti"
    },
    "asset": {
      "confirmation": "Opravdu chcete vymazat médium s názvem ''{ name }'' kampaně ''{ campaignName }''?"
    },
    "associated.lifts": "Přiřazené výtahy",
    "bad.credentials": "Uživatelské jméno a/nebo heslo jsou neplatné.",
    "blocked": {
      "contactButton": "Kontaktujte oddělení prodeje",
      "explanation": "Vaše práva jsou zrušená, protože platnost Vaší registrace vypršela. Další informace najdete v našem prodejním oddělení",
      "title": "Platnost balíku produktu vypršela"
    },
    "button": {
      "add": "Přidat",
      "apply.changes": "Převzít změny",
      "save": "Uložit"
    },
    "campaign": {
      "add": {
        "description": "Kliknutím přidejte nové kampaně.",
        "label": "Přidat kampaň"
      },
      "associated": "Kampaně pro ''{ name }''",
      "count": "{count, plural,\n  =0 {Nebyla přiřazena žádná kampaň}\n  one {1 přiřazená kampaň}\n  other {{count} přiřazené kampaně}\n}",
      "delete": {
        "confirmation": "Opravdu chcete vymazat kampaň s názvem ''{ name }''?",
        "title": "Vymazat kampaň"
      },
      "dirty": {
        "description": "Kampaň obsahuje neuložené změny. Opravdu chcete stránku opustit?",
        "title": "Neuložená kampaň"
      },
      "landing": {
        "explanation": "Zorganizujte si své reklamní kampaně a přiřaďte je ke svým koncovým zařízením flexy<span class=\"flexy-P\">P</span>age.",
        "loading": "Načtou se obsahy kampaně flexy<span class=\"flexy-P\">P</span>age.",
        "welcome": "Vítejte v kampani u flexy<span class=\"flexy-P\">P</span>age Campaign"
      },
      "loading": "Kampaň se nahrává.",
      "play.preview": "Náhled"
    },
    "campaign.logo": "flexyPage Campaign Logo",
    "campaigns": {
      "edit": {
        "description": "Kliknutím sem můžete upravit své kampaně.",
        "label": "Zpracovat kampaně"
      }
    },
    "capacity.notAvailable": "Nemáte přiřazenou žádnou roli. Obraťte se na administrátora.",
    "client-company": {
      "count": "{count, plural,\n  =0 { žádná klientská společnost}\n  one {1 klientská společnost}\n  other {{count} Klientská společnost}\n}",
      "label": "Klientská společnost"
    },
    "company": {
      "add": {
        "description": "Kliknutím přidejte nové společnosti.",
        "label": "Přidat společnost"
      },
      "admin": {
        "confirmation": "Opravdu chcete z této společnosti odstranit správce s názvem ''{ name }''?",
        "delete": "Odstranit administrátora společnosti"
      },
      "confirmation": "Opravdu chcete vymazat společnost s názvem ''{ name }''?",
      "count": "{count, plural,\n  =0 { Žádné společnosti}\n  one {1 společnost}\n  other {{count} Společnosti}\n}",
      "create": "Vytvořit novou společnost",
      "data": {
        "address": "Adresa společnosti",
        "customerId": "ID společnosti",
        "name": "Název společnosti"
      },
      "delete": "Vymazat společnost",
      "logo": {
        "label": "Logo společnosti",
        "not.set": "Nebylo nahráno žádné logo společnosti"
      },
      "none-selected": "Zde můžete spravovat své společnosti, přidávat nové nebo upravovat existující.",
      "not.set": "Není přiřazeno žádné ID společnosti",
      "single": "Společnost"
    },
    "create.campaign": "Vytvořit kampaň",
    "create.new.campaign": "Kliknutím vytvoříte novou kampaň.",
    "device": {
      "add": {
        "description": "Kliknutím přidejte nová zařízení.",
        "label": "Přidat zařízení",
        "tenant-directory": "Přidejte do tohoto zařízení adresář tenanta."
      },
      "addCampaigns": "Přidejte kampaně do tohoto zařízení.",
      "confirmation": "Opravdu chcete vymazat zařízení s názvem ''{ name }''?",
      "count": "{count, plural,\n  =0 { Žádné zařízení}\n  one {1 zařízení}\n  other {{count} Zařízení}\n}",
      "create": {
        "fail": "Vytvoření zařízení se nezdařilo. Obraťte se na administrátora."
      },
      "delete": "Vymazat zařízení",
      "manage": {
        "description": "Click here to manage your devices."
      },
      "none-selected": "Přidejte nové zařízení nebo vyberte zařízení ze seznamu."
    },
    "device-group": {
      "addCampaigns": "Přidejte kampaně do této skupiny zařízení.",
      "addDevices": "Přidejte zařízení do této skupiny zařízení.",
      "addMembers": "Přidejte správce obsahu k této skupině zařízení.",
      "confirmation": "Opravdu chcete vymazat skupinu s názvem ''{ name }''?",
      "create": "Vytvořit skupinu zařízení",
      "delete": "Vymazat skupinu zařízení",
      "label": "Skupiny zařízení",
      "members": "Správce obsahu",
      "name": {
        "placeholder": "Název skupiny zařízení"
      },
      "none-selected": "Přidejte novou skupinu zařízení nebo vyberte jednu ze seznamu."
    },
    "dialog": {
      "text": {
        "change.layout": "Opravdu chcete změnit rozvržení? Záznamy se mohou ztratit"
      },
      "title": {
        "change.layout": "Změnit rozložení",
        "create.layout.view": "Vytvořit nový náhled",
        "folder.media.in.use": "Média se používají v této složce",
        "media.in.use": "Používá se médium",
        "start.campaign": "Spustit novou kampaň"
      }
    },
    "duration": {
      "hours": "hodiny",
      "minutes": "minuty",
      "seconds": "seconds"
    },
    "effective": "Skutečné využití úložiště",
    "error": {
      "assetOnCampaignStorageLimit": "Obrázek/video není přidáno. Je překročené maximální omezení paměti pro zařízení.",
      "campaignOnDeviceStorageLimit": "Kampaň se nedá nahrát. Je překročené maximální omezení paměti.",
      "company.name.exists": "Společnost se stejným názvem již existuje.",
      "customer.id.exists": "Toto ID zákazníka již existuje.",
      "device.has.license": "Toto zařízení již má licenci.",
      "devicesMaxCap": "Nelze doplnit žádná dodatečná zařízení.",
      "email.exists": "Tento e-mail již existuje.",
      "existingDevice": "Zařízení ''{ deviceSerialNumber }'' je již přidruženo k firmě ''{ companyName }''.",
      "folder.file.exists": "Soubor již v této složce existuje.",
      "invalidEmail": "Zadaná e-mailová adresa není platná.",
      "license.key.exists": "Essa chave de licença já existe.",
      "name.not_blank": "Pole pro zadání názvu nesmí být prázdné.",
      "not.found": "Error: Not Found",
      "not.serialNumber": "To není žádné sériové číslo.",
      "occurred": "Vyskytla se chyba",
      "only.image": "Je povolen pouze obrázek.",
      "password": {
        "mismatch": "Pole hesla se musí shodovat.",
        "no.minimum.character.length": "Heslo musí obsahovat alespoň 8 znaků.",
        "no.minimum.character.types": "Heslo musí obsahovat alespoň 3 ze 4:\nčísla, speciální znaky, velká nebo malá písmena.",
        "not_blank": "Pole pro zadání hesla nesmí být prázdné."
      },
      "rss-feed.exists": "Kanál RSS se stejnou adresou URL již existuje.",
      "server.zero.status": "Během nahrávání došlo k chybě. Možná byl soubor větší, než je aktuálně povoleno.",
      "storageLimit": "Soubor nemohl být nahraný. Maximální omezení paměti se překročí.",
      "tenant-directory.not.exists": "Požadovaný adresář tenanta neexistuje.",
      "too.many.login.attempts": "Příliš mnoho pokusů o přihlášení",
      "updating.user": "Chyba při aktualizaci uživatele.",
      "url.not.found": "Požadovaná adresa URL ''{ url }'' nebyla nalezena.",
      "user": {
        "email.not_blank": "Pole pro zadání e-mailu nesmí být prázdné."
      },
      "usersMaxCap": "Nelze pozvat žádné další uživatele.",
      "value.not_blank": "Tato hodnota nesmí být prázdná."
    },
    "explanatory": {
      "text": {
        "scheduled.appearance": "Proveďte nastavení, aby se toto rozložení zobrazilo na <b>základě času</b>.",
        "triggered.appearance": "Proveďte nastavení, aby se toto rozložení zobrazilo na <b>základě stavu výtahu</b>."
      }
    },
    "files.to.upload": "Zůstávající soubory",
    "floor": {
      "auto-name": "Poschodí",
      "confirmation": "Opravdu chcete odstranit patro s názvem ''{ name }'' z adresáře nájemců ''{belongsToName}''?",
      "count": "{ count, plural,\n  =0 {Žádné podlahy}\n  one {1 patro}\n  other {{ count } pater}\n}",
      "delete": "Odstranit patro",
      "label": "Podlahový alias"
    },
    "folder.empty": "Adresář médií je prázdný.",
    "folder.exists": "Složka již existuje.",
    "friday.short": "pá",
    "heading": {
      "event.triggered.appearance": "Zobrazení na základě událostí",
      "scheduled.appearance": "Zobrazení na základě času"
    },
    "invalid.file.type": "Typ souboru není podporovaný.",
    "label": {
      "address": "Adresa",
      "adminUsers": "Administrátoři uživatelů",
      "admins": "Administrátoři",
      "campaign": "Kampaň",
      "campaign.name": "Název kampaně",
      "campaigns": "Kampaně",
      "cancel": "Zrušit",
      "companies": "Společnosti",
      "company": "Společnosti",
      "confirm": "Potvrdit",
      "content": "Obsahy",
      "contentManagers": "Manažer obsahu",
      "create": {
        "campaign": "Nová kampaň",
        "device": "Vytvořit nové zařízení",
        "floor": "Vytvořte novou podlahu",
        "folder": "Vytvořit nový pořadač",
        "layout.view": "Vytvořit náhled",
        "pdf-link": "Vytvořit nový odkaz na PDF",
        "rss-feed": "Vytvořit nový RSS kanál",
        "tenant": "Vytvořit nového tenanta",
        "tenant-directory": "Vytvořit nový adresář tenanta"
      },
      "date": {
        "end": "Datum ukončení",
        "start": "Datum začátku"
      },
      "days": "Dny",
      "device": "Zařízení",
      "device-group.campaigns": "Přidružené kampaně se skupinami zařízení",
      "device-groups": "Skupiny zařízení",
      "devices": "Zařízení",
      "display.duration": "Doba zobrazení",
      "duration": "Doba",
      "edit": "Upravit",
      "email": "E-mail",
      "emptySearchResults": "Nenašly se žádné události pro ''{ search }''?",
      "event": "Událost",
      "firstName": "Jméno",
      "history": "Historie",
      "lastName": "Příjmení",
      "license": {
        "event": {
          "associated-to-device": "Licence přidělená zařízení.",
          "created": "Licence vyhotovena.",
          "dissociated-from-device": "Licence ze zařízení odstraněna",
          "updated-valid-until": "Platnost prodloužena."
        },
        "many": "Licence",
        "one": "Licence"
      },
      "missingAddress": "Není zadána žádná adresa.",
      "name": "Jméno",
      "noAdminUsers": "Žádní administrátoři uživatelů",
      "notes": "Poznámky",
      "order.licenses": "Objednat licence",
      "override.displayDuration": "Ručně nastavit dobu zobrazení",
      "password": "Heslo",
      "passwordConfirm": "Potvrdit heslo",
      "product-package": {
        "companyUrl": "Url adresa společnosti'",
        "settings": "Nastavení produktu"
      },
      "save": "Uložit",
      "selectCapacity": "Vyberte funkci",
      "selectCompany": "Vyberte společnost",
      "serialNumber": "Sériové číslo",
      "tenant-directories": "Adresáře nájemců",
      "tenants.per.floor": "Nájemníci na patro",
      "time": {
        "end": "Čas ukončení",
        "start": "Čas spuštění"
      },
      "users": "Uživatel",
      "validUntil": "Platné do"
    },
    "last.online": "Naposledy online",
    "layoutView": {
      "confirmation": "Opravdu chcete vymazat náhled rozložení s názvem ''{ name }'' kampaně ''{ campaignName }''?",
      "delete": "Vymazat náhled rozložení"
    },
    "library": {
      "campaign": {
        "empty": "Knihovna kampaní je prázdná.",
        "heading": "Knihovna médií",
        "loading": "Kampaně se načítají"
      },
      "device": {
        "empty": "Knihovna zařízení je prázdná.",
        "heading": "Knihovna zařízení",
        "loading": "Načítají se zařízení."
      },
      "folder": {
        "add-media": "Přidejte obrázky přes oblast Drag'n'Drop a zobrazí se zde.",
        "empty": "Pořadač ''{ name }'' je prázdný."
      },
      "license": {
        "empty": "Knihovna licencí je prázdná.",
        "heading": "Knihovna licencí",
        "loading": "Načítají se licence."
      },
      "media": {
        "empty": "Přidejte obrázky do své knihovny médií a zobrazí se zde.",
        "heading": "Knihovna médií",
        "loading": "Knihovna médií se načítá."
      },
      "members": {
        "empty": "Ve Vaší společnosti nejsou momentálně žádní správcové obsahu.",
        "heading": "Správce obsahu",
        "loading": "Uživatelé se načítají."
      },
      "tenant-directory": {
        "empty": "Knihovna adresářů tenanta je prázdná.",
        "heading": "Knihovna adresářů nájemců",
        "loading": "Adresáře nájemců se načítají."
      }
    },
    "license": {
      "confirmation": "Opravdu chcete odstranit licenci ''{ key }'' ze zařízení ''{  serialNumber }''?",
      "count": "{count, plural,\n  =0 { Žádné licence}\n  one {1 licence}\n  other {{count} Licence}\n}",
      "expired": "vypršela",
      "none": "Není přidělena žádná licence",
      "not.in.use": "licence se nepoužívá",
      "remove": "Odstranit licenci ze zařízení"
    },
    "list": {
      "empty": {
        "campaign": "Nebyla vytvořena žádná kampaň.",
        "company": "K dispozici není žádná společnost.",
        "device": "K dispozici není žádné zařízení.",
        "device-group": "Nebyly vytvořeny žádné skupiny zařízení.",
        "license": "K dispozici nejsou žádné licence.",
        "no.add.permission.user-guide": "Nemáte oprávnění na vytvoření nového/nové { type }. Obraťte se na svého administrátora.",
        "tenant-directory": "Zatím nebyl vytvořen žádný adresář tenanta.",
        "user": "Žádní uživatelé v této kategorii.",
        "user-guide": "Kliknutím na znaménko plus (+) v levém horním rohu sloupce můžete přidat nové."
      }
    },
    "loading": {
      "assets": "Načítají se aktiva.",
      "campaigns": "Načítají se kampaně.",
      "capacities": "Nakládací kapacita.",
      "devices": "Nakládací zařízení.",
      "medium.assignments": "Načítání přiřazení média.",
      "members": "Načítání seznamu členů.",
      "tenant-directories": "Načítání adresářů tenanta.",
      "users": "Načítání uživatelů."
    },
    "login": {
      "button": "Přihlásit se",
      "button-user-edit": "Uložit profil uživatele",
      "button-user-init": "Vytvořit administrátora uživatelů",
      "button-user-reset": "Odeslat",
      "email": "E-mail",
      "first-name": "Jméno",
      "last-name": "Příjmení",
      "link-password-reset": "Obnovit heslo",
      "links": {
        "impressum": "Tiráž",
        "privacy-policy": "Prohlášení o ochraně údajů"
      },
      "password": "Heslo",
      "repeat_password": "Zopakovat heslo",
      "session": {
        "expires": {
          "text": "Relace vyprší. Potvrďte za účelem aktualizace.",
          "title": "Vypršení časového limitu relace"
        }
      },
      "title": "Přihlášení uživatele",
      "title-user-edit": "Profil uživatelů",
      "title-user-init": "Vytvořit administrátora uživatelů",
      "title-user-reset": "Resetovat uživatele"
    },
    "manage.devices": "Správa zařízení",
    "manage.users": "Správa uživatelů",
    "max.files.exceeded": "Nelze nahrát žádné další soubory.",
    "media": {
      "file": {
        "confirmation": "Opravdu chcete vymazat médium s názvem ''{ name }''?",
        "delete": "Vymazat médium"
      },
      "folder": {
        "confirmation": "Opravdu chcete vymazat adresář s názvem ''{ name }''?",
        "delete": "Vymazat adresář"
      },
      "in": {
        "use": "Médium používá/používají následující:"
      }
    },
    "monday.short": "po",
    "nav.logout": "Odhlásit",
    "new": "Nové",
    "new.folder": "Nová složka",
    "new.pdf-file": "Nový soubor PDF",
    "new.pdf-link": "Nový odkaz na PDF",
    "new.rss-feed": "Nový RSS kanál",
    "oem-company": {
      "count": "{count, plural,\n  =0 { žádná společnost OEM}\n  one {1 společnost OEM}\n  other {{count} Společnost OEM}\n}",
      "label": "Společnost OEM"
    },
    "of": "od",
    "pdf": "PDF",
    "pdf-link.exists": "Odkaz na soubor PDF se stejným názvem již existuje.",
    "placeholder": {
      "campaign.name": "Zadejte nový název kampaně",
      "floor.name": "Zadejte název podlahy",
      "folder.name": "Název složky",
      "layout.view.name": "Zadejte název pro náhled",
      "notes": "Zadejte dodatečné informace",
      "pdf-link.url": "PDF URL",
      "rss-feed.url": "URL RSS kanálu",
      "search": "Vyhledávat",
      "serialNumber": "Sériové číslo",
      "tenant-directory.name": "Zadejte název adresáře tenanta",
      "tenant.name": "Zadejte jméno nájemce"
    },
    "product-package": {
      "cancel": "Vypovědět výrobek",
      "canceled": "Vypovězený",
      "cancellation": "Výpověď",
      "companyUrl": "Url adresa společnosti",
      "companyUrl.exists": "Url adresa společnosti již existuje",
      "companyUrl.not.set": "Url adresa společnosti není stanovená",
      "count": "{count, plural,\n  =0 { Žádný produkt}\n  one {1 produkt}\n  other {{count} Produkty}\n}",
      "description": "Popis",
      "expired": "Vypršel",
      "loading": "Produkt se načte.",
      "maxDevices": "Maximální počet zařízení",
      "maxDevices.not.set": "Maximální počet zařízení není stanoven",
      "maxUsers": "Maximální počet uživatelů",
      "maxUsers.not.set": "Maximální počet uživatelů není stanoven",
      "none": "nebylo přiřazeno žádné balení produktu",
      "not.in.use": "nepoužívá se",
      "remove": "Vymazat produkt",
      "revokeCancellation": "Odvolat výpověď",
      "status": "Stav",
      "statusAutoExtend": "Váš produkt se automaticky prodlouží dne",
      "statusCanceled": "Váš produkt byl vypovězený dne",
      "statusExpired": "Platnost Vašeho produktu vypršela.",
      "storageLimit": "Omezení paměti",
      "storageLimit.min": "Omezení paměti musí být větší.",
      "storageLimit.not.set": "Omezení paměti není stanoveno"
    },
    "query.confirmation.media.deletion": "Chcete médium navzdory tomu vymazat? Potom bude z těchto kampaní odstraněno a/nebo adresáře nájemců.",
    "rss": "RSS",
    "saturday.short": "so",
    "select.capacity": "Vybrat oprávnění",
    "select.event": "Vybrat událost...",
    "serialNumber": "Sériové číslo",
    "server.big.file.size": "Velikost souboru je příliš velká pro konfiguraci serveru",
    "sunday.short": "ne",
    "symbol": {
      "seconds": "(s)"
    },
    "technical.error": "Technická chyba",
    "tenant": {
      "confirmation": "Opravdu chcete smazat nájemce s názvem ''{ name }'' z podlahy ''{ belongsToName }''?",
      "delete": "Smazat tenanta",
      "label": "Nájemce"
    },
    "tenant-directory": {
      "add": {
        "description": "Kliknutím sem přidáte nové adresáře tenanta.",
        "label": "Přidat adresář nájemce"
      },
      "count": "{count, plural,\n  =0 {Žádné adresáře tenanta}\n  one {1 adresář nájemce}\n  other {{count} adresáře tenanta}\n}",
      "delete": {
        "confirmation": "Opravdu chcete smazat adresář nájemce s názvem ''{ name }''?",
        "title": "Odstranit adresář nájemce"
      },
      "label": "Adresář nájemců",
      "loading": "Načítání adresáře tenanta",
      "none.selected": "Přidejte nový adresář tenanta nebo vyberte jeden ze seznamu."
    },
    "text.no.campaign.selected": "Přidejte novou kampaň nebo vyberte jednu ze seznamu.",
    "thumbnail": "Miniatura",
    "thursday.short": "čt",
    "toggle.navigation": "Přepnout navigaci",
    "tuesday.short": "út",
    "type": "Typ",
    "unknown.error": "An error occurred. Please, contact an Administrator.",
    "unknown.server.error": "Rozhraní API odpovědělo 'Neznámá chyba serveru'.",
    "upload": {
      "avatar": {
        "preview": "Avatar k nahrání",
        "uploading": "Avatar se nahrává"
      },
      "canceled": "Nahrávání je zrušeno.",
      "drop.label": "<span class=\"drop-label\">Soubory <span class=\"text-orange\">sem</span><span> natáhněte nebo je </span><span class=\"text-orange\"> prohledejte</span>.</span>",
      "logo": {
        "preview": "Logo na nahrání",
        "uploading": "Logo se nahrává"
      }
    },
    "url.not.pdf": "URL neobsahuje soubor pdf.",
    "used.storage": "Obsazení paměti",
    "user": {
      "add": {
        "description": "Kliknutím pozvete nové uživatele.",
        "invite": "Pozvat uživatele",
        "label": "Přidat uživatele",
        "placeholder": "Zapsat e-mailovou adresu"
      },
      "avatar": {
        "image": "Obrázek avatara uživatele",
        "not.set": "Nebyl nahrán žádný uživatelský avatar"
      },
      "companyAdmin": {
        "count": "{count, plural,\n  =0 { Žádní administrátoři}\n  one {1 administrátor}\n  other {{count} Administrátoři}\n}"
      },
      "companyContentManager": {
        "count": "{count, plural,\n  =0 { Žádný správce obsahu}\n  one {1 Správce obsahu}\n  other {{count} Správce obsahu}\n}"
      },
      "companyRemoveUser": "Odstranit uživatele",
      "companyRemoveUserConfirmation": "Opravdu chcete odstranit uživatele s e-mailem ''{ email }'?",
      "reset.password": {
        "fail": "Pro uvedený e-mail se nenašel žádný uživatel.",
        "success": "Obnovovací e-mail byl odeslán na zadaný e-mailový účet. Postupujte podle pokynů."
      }
    },
    "userRoleNames": {
      "admin": "Admin",
      "clientAdmin": "Administrátor klientů",
      "contentManager": "Správce obsahu'",
      "oemAdmin": "OEM admin"
    },
    "wednesday.short": "st"
  }], ["de", {
    "Acknowledgement received": "Quittierung empfangen",
    "Active signal": "Active signal",
    "Campaigns": "Kampagnen",
    "Companies": "Unternehmen",
    "Devices": "Geräte",
    "Door Number": "Türnummer",
    "Door Status": "Türstatus",
    "Floor": "Etage",
    "Groups": "Gruppen",
    "Licenses": "Lizenzen",
    "MB": "MB",
    "Media": "Medien",
    "Status": "Status",
    "This value is already used.": "Dieser Wert ist bereits in Verwendung.",
    "Type": "Art",
    "Users": "Nutzer",
    "acknowledgement.received": "Quittierung empfangen",
    "act.as.admin": "Als Admin arbeiten",
    "add.campaign": "Kampagne hinzufügen",
    "api.unreachable": "Die API ist nicht erreichbar.",
    "area": {
      "bottom": "Bereich Unten",
      "center": "Bereich Mitte",
      "left": "Bereich Links",
      "main": "Hauptbereich",
      "right": "Bereich Rechts",
      "top": "Bereich Oben"
    },
    "asset": {
      "confirmation": "Möchten Sie wirklich das Medium mit dem Namen ''{ name }'' von der Kampagne ''{ campaignName }'' löschen?"
    },
    "associated.lifts": "Zugeordnete Aufzüge",
    "bad.credentials": "Nutzername und/oder Passwort sind ungültig.",
    "blocked": {
      "contactButton": "Contact Sales Department",
      "explanation": "Your priviledges are revoked, because your registration has expired. For further information please refer to our sale department",
      "title": "Product Package Expired"
    },
    "button": {
      "add": "Hinzufügen",
      "apply.changes": "Änderungen übernehmen",
      "save": "Speichern"
    },
    "campaign": {
      "add": {
        "description": "Klicken Sie, um neue Kampagnen hinzuzufügen.",
        "label": "Kampagne hinzufügen"
      },
      "associated": "Kampagnen für ''{name}''",
      "count": "{count, plural,\n  =0 {Keine Kampagne zugeordnet}\n  one {1 zugeordnete Kampagne}\n  other {{count} zugeordnete Kampagnen}\n}",
      "delete": {
        "confirmation": "Möchten Sie wirklich die Kampagne mit dem Namen ''{ name }'' löschen?",
        "title": "Kampagne löschen"
      },
      "dirty": {
        "description": "Die Kampagne hat nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?",
        "title": "Nicht gespeicherte Kampagne"
      },
      "landing": {
        "explanation": "Organisieren Sie Ihre Werbekampagnen und weisen Sie diese Ihren flexy<span class=\"flexy-P\">P</span>age Endgeräten zu.",
        "loading": "flexy<span class=\"flexy-P\">P</span>age Campaign Inhalte werden geladen.",
        "welcome": "Willkommen bei flexy<span class=\"flexy-P\">P</span>age Campaign"
      },
      "loading": "Kampagne wird geladen.",
      "play.preview": "Vorschau"
    },
    "campaign.logo": "Campaign Logo",
    "campaigns": {
      "edit": {
        "description": "Klicken Sie hier, um Ihre Kampagnen zu bearbeiten.",
        "label": "Kampagnen bearbeiten"
      }
    },
    "capacity.notAvailable": "Sie haben keine zugewiesene Rolle. Bitte wenden Sie sich an einen Administrator.",
    "client-company": {
      "count": "{count, plural,\n  =0 {kein Mandanten-Unternehmen}\n  one {1 Mandanten-Unternehmen}\n  other {{count} Mandanten-Unternehmen}\n}",
      "label": "Mandanten-Unternehmen"
    },
    "company": {
      "add": {
        "description": "Klicken Sie, um neue Unternehmen hinzuzufügen.",
        "label": "Unternehmen hinzufügen"
      },
      "admin": {
        "confirmation": "Möchten Sie wirklich den Admin mit dem Namen ''{ name }'' von diesem Unternehmen entfernen?",
        "delete": "Admin vom Unternehmen entfernen"
      },
      "confirmation": "Möchten Sie wirklich das Unternehmen mit dem Namen ''{ name }'' löschen?",
      "count": "{count, plural,\n  =0 {Keine Unternehmen}\n  one {1 Unternehmen}\n  other {{count} Unternehmen}\n}",
      "create": "Neues Unternehmen anlegen",
      "data": {
        "address": "Unternehmens-Adresse",
        "customerId": "Unternehmens-ID",
        "name": "Name für das Unternehmen"
      },
      "delete": "Unternehmen löschen",
      "logo": {
        "label": "Unternehmenslogo",
        "not.set": "Kein Unternehmenslogo heraufgeladen"
      },
      "none-selected": "Verwalten Sie hier Ihre Unternehmen, fügen Sie neue hinzu oder bearbeiten Sie existierende.",
      "not.set": "Keine Unternehmens-ID zugewiesen",
      "single": "Unternehmen"
    },
    "create.campaign": "Kampagne anlegen",
    "create.new.campaign": "Klicken Sie, um eine neue Kampagne anzulegen.",
    "device": {
      "add": {
        "description": "Klicken Sie, um neue Geräte hinzuzufügen.",
        "label": "Gerät hinzufügen",
        "tenant-directory": "Fügen Sie diesem Gerät ein Mieterverzeichnis hinzu."
      },
      "addCampaigns": "Fügen Sie Kampagnen zu diesem Gerät hinzu.",
      "confirmation": "Möchten Sie wirklich das Gerät mit dem Namen ''{ name }'' löschen?",
      "count": "{count, plural,\n  =0 {Kein Gerät}\n  one {1 Gerät}\n  other {{count} Geräte}\n}",
      "create": {
        "fail": "Geräte Erstellung fehlgeschlagen. Bitte wenden Sie sich an einen Administrator."
      },
      "delete": "Gerät löschen",
      "manage": {
        "description": "Klicken Sie, um Ihre Geräte zu verwalten."
      },
      "none-selected": "Fügen Sie ein neues Gerät hinzu oder wählen Sie eines aus der Liste."
    },
    "device-group": {
      "addCampaigns": "Fügen Sie Kampagnen zu dieser Gerätegruppe hinzu.",
      "addDevices": "Fügen Sie Geräte zu dieser Gerätegruppe hinzu.",
      "addMembers": "Fügen Sie einen Inhaltsverwalter zu dieser Gerätegruppe hinzu.",
      "confirmation": "Möchten Sie wirklich die Gerätegruppe mit dem Namen ''{ name }'' löschen?",
      "create": "Gerätegruppe anlegen",
      "delete": "Gerätegruppe löschen",
      "label": "Gerätegruppen",
      "members": "Inhaltsverwalter",
      "name": {
        "placeholder": "Name der Gerätegruppe"
      },
      "none-selected": "Fügen Sie eine neue Gerätegruppe hinzu oder wählen Sie eine aus der Liste."
    },
    "dialog": {
      "text": {
        "change.layout": "Möchten Sie wirklich das Layout ändern? Die Einträge können verloren gehen"
      },
      "title": {
        "change.layout": "Layout wechseln",
        "create.layout.view": "Neue Ansicht anlegen",
        "folder.media.in.use": "Medien in diesem Ordner werden verwendet",
        "media.in.use": "Das Medium wird verwendet",
        "start.campaign": "Starten Sie eine neue Kampagne"
      }
    },
    "duration": {
      "hours": "Stunden",
      "minutes": "Minuten",
      "seconds": "Sekunden"
    },
    "effective": "Tatsächlich verwendeter Speicher",
    "error": {
      "assetOnCampaignStorageLimit": "Das Bild/Video kann nicht hinzugefügt werden. Die maximale Speicherbeschränkung für eines der Geräte wird überschritten.",
      "campaignOnDeviceStorageLimit": "Die Kampagne kann nicht heraufgeladen werden. Die maximale Speicherbeschränkung wird überschritten.",
      "company.name.exists": "Eine gleichnamige Firma existiert bereits.",
      "customer.id.exists": "Diese Kunden-ID ist bereits vorhanden.",
      "device.has.license": "Dieses Gerät hat bereits eine Lizenz.",
      "devicesMaxCap": "Es können keine zusätzlichen Geräte ergänzt werden.",
      "email.exists": "Diese E-Mail existiert bereits.",
      "existingDevice": "Gerät ''{ deviceSerialNumber }'' ist bereits mit der Firma ''{ companyName }'' verknüpft.",
      "folder.file.exists": "Die Datei ist bereits in diesem Ordner vorhanden.",
      "invalidEmail": "Die angegebene Email-Adresse ist nicht gültig.",
      "license.key.exists": "Dieser Lizenzschlüssel ist bereits vorhanden.",
      "name.not_blank": "Das Eingabefeld für den Namen darf nicht leer sein.",
      "not.found": "Fehler: Nicht gefunden.",
      "not.serialNumber": "Das ist keine Seriennummer.",
      "occurred": "Ein Fehler ist aufgetreten",
      "only.image": "Nur Bild ist erlaubt.",
      "password": {
        "mismatch": "Die Passworte müssen übereinstimmen.",
        "no.minimum.character.length": "Passwort muss mindestens 8 Zeichen beinhalten.",
        "no.minimum.character.types": "Das Passwort muss mindestens 3 der 4 enthalten:\nZahlen, Sonderzeichen, Groß- oder Kleinbuchstaben.",
        "not_blank": "Das Eingabefeld für das Passwort darf nicht leer sein."
      },
      "rss-feed.exists": "Ein RSS-Feed mit derselben URL ist bereits vorhanden.",
      "server.zero.status": "Beim Hochladen ist ein Fehler aufgetreten. Möglicherweise war die Datei größer als derzeit zulässig.",
      "storageLimit": "Die Datei kann nicht heraufgeladen werden. Die maximale Speicherbeschränkung wird überschritten.",
      "tenant-directory.not.exists": "Das angeforderte Mieterverzeichnis existiert nicht.",
      "too.many.login.attempts": "Zu viele Login Versuche",
      "updating.user": "Fehler beim Aktualisieren des Nutzers.",
      "url.not.found": "Die angeforderte URL ''{ url }'' wurde nicht gefunden.",
      "user": {
        "email.not_blank": "Das Eingabefeld für die E-Mail darf nicht leer sein."
      },
      "usersMaxCap": "Es können keine weiteren Nutzer eingeladen werden.",
      "value.not_blank": "Dieser Wert darf nicht leer sein."
    },
    "explanatory": {
      "text": {
        "scheduled.appearance": "Nehmen Sie hier Einstellungen vor, um dieses Layout <b>zeitgesteuert</b> anzuzeigen.",
        "triggered.appearance": "Nehmen Sie hier Einstellungen vor, um dieses Layout durch den <b>Aufzugszustand gesteuert</b> anzuzeigen."
      }
    },
    "files.to.upload": "Verbleibende Dateien",
    "floor": {
      "auto-name": "Etage",
      "confirmation": "Möchten Sie das Stockwerk mit dem Namen ''{ name }'' wirklich aus dem Mieterverzeichnis ''{ belongsToName }'' löschen?",
      "count": "{ count, plural,\n  =0 {Keine Etagen}\n  one {1 Etage}\n  other {{ count } Etagen}\n}",
      "delete": "Etage löschen",
      "label": "Etagen-Alias"
    },
    "folder.empty": "Medien-Verzeichnis ist leer.",
    "folder.exists": "Der Ordner existiert bereits.",
    "friday.short": "Fr",
    "heading": {
      "event.triggered.appearance": "Ereignisgesteuerte Anzeige",
      "scheduled.appearance": "Zeitgesteuerte Anzeige"
    },
    "invalid.file.type": "Dateityp wird nicht unterstützt.",
    "label": {
      "address": "Adresse",
      "adminUsers": "Admin Nutzer",
      "admins": "Administratoren",
      "campaign": "Kampagne",
      "campaign.name": "Kampagnenname",
      "campaigns": "Kampagnen",
      "cancel": "Abbrechen",
      "companies": "Unternehmen",
      "company": "Unternehmen",
      "confirm": "Bestätigen",
      "content": "Inhalte",
      "contentManagers": "Inhalts-Manager",
      "create": {
        "campaign": "Neue Kampagne",
        "device": "Neues Gerät anlegen",
        "floor": "Neue Etage erstellen",
        "folder": "Neuen Ordner anlegen",
        "layout.view": "Ansicht anlegen",
        "pdf-link": "Neuen PDF-Link anlegen",
        "rss-feed": "Neuen RSS-Feed erstellen",
        "tenant": "Neuen Mieter anlegen",
        "tenant-directory": "Neues Mieterverzeichnis erstellen"
      },
      "date": {
        "end": "Enddatum",
        "start": "Startdatum"
      },
      "days": "Tage",
      "device": "Gerät",
      "device-group.campaigns": "Gerätegruppen zugeordnete Kampagnen",
      "device-groups": "Gerätegruppen",
      "devices": "Geräte",
      "display.duration": "Anzeigedauer",
      "duration": "Dauer",
      "edit": "Bearbeiten",
      "email": "Email",
      "emptySearchResults": "Keine Ergebnisse für ''{ search }'' gefunden.",
      "event": "Ereignis",
      "firstName": "Vorname",
      "history": "Historie",
      "lastName": "Nachname",
      "license": {
        "event": {
          "associated-to-device": "Lizenz Gerät zugeordnet.",
          "created": "Lizenz erstellt.",
          "dissociated-from-device": "Lizenz von Gerät entfernt",
          "updated-valid-until": "Gültigkeit verlängert."
        },
        "many": "Lizenzen",
        "one": "Lizenz"
      },
      "missingAddress": "Keine Adresse vergeben.",
      "name": "Name",
      "noAdminUsers": "Keine Admin Nutzer",
      "notes": "Notizen",
      "order.licenses": "Lizenzen bestellen",
      "override.displayDuration": "Anzeigedauer manuell setzen",
      "password": "Passwort",
      "passwordConfirm": "Passwort bestätigen",
      "product-package": {
        "companyUrl": "Unternehmen Url",
        "settings": "Produkt Einstellungen"
      },
      "save": "Speichern",
      "selectCapacity": "Wählen Sie eine Funktion",
      "selectCompany": "Wählen Sie ein Unternehmen",
      "serialNumber": "Seriennummer",
      "tenant-directories": "Mieter-Verzeichnisse",
      "tenants.per.floor": "Etagen- und Mieterübersicht",
      "time": {
        "end": "Endzeit",
        "start": "Startzeit"
      },
      "users": "Nutzer",
      "validUntil": "Gültig bis"
    },
    "last.online": "Zuletzt Online",
    "layoutView": {
      "confirmation": "Möchten Sie wirklich die Layout-Ansicht mit dem Namen ''{ name }'' von der Kampagne ''{ campaignName }'' löschen?",
      "delete": "Layout-Ansicht löschen"
    },
    "library": {
      "campaign": {
        "empty": "Kampagnenbibliothek ist leer.",
        "heading": "Kampagnenbibliothek",
        "loading": "Kampagnen werden geladen"
      },
      "device": {
        "empty": "Gerätebibliothek is leer.",
        "heading": "Gerätebibliothek",
        "loading": "Geräte werden geladen."
      },
      "folder": {
        "add-media": "Fügen Sie Bilder über den Drag'n'Drop Bereich hinzu und sie werden hier angezeigt.",
        "empty": "Der Ordner ''{ name }'' ist leer."
      },
      "license": {
        "empty": "Lizenzbibliothek ist leer.",
        "heading": "Lizenzbibliothek",
        "loading": "Lizenzen werden geladen."
      },
      "media": {
        "empty": "Fügen Sie Bilder zu Ihrer Medienbibliothek hinzu und sie werden hier angezeigt.",
        "heading": "Medienbibliothek",
        "loading": "Medienbibliothek wird geladen."
      },
      "members": {
        "empty": "Zur Zeit gibt es keine Inhaltsverwalter in Ihrem Unternehmen.",
        "heading": "Inhaltsverwalter",
        "loading": "Nutzer werden geladen."
      },
      "tenant-directory": {
        "empty": "Mieterverzeichnisbibliothek ist leer.",
        "heading": "Mieterverzeichnisbibliothek",
        "loading": "Mieterverzeichnisse werden geladen."
      }
    },
    "license": {
      "confirmation": "Möchten Sie wirklich die Lizenz ''{ key }'' von dem Gerät ''{ serialNumber }'' entfernen?",
      "count": "{count, plural,\n  =0 {Keine Lizenzen}\n  one {1 Lizenz}\n  other {{count} Lizenzen}\n}",
      "expired": "abgelaufen",
      "none": "Keine Lizenz zugeordnet",
      "not.in.use": "Lizenz wird nicht verwendet",
      "remove": "Lizenz vom Gerät entfernen"
    },
    "list": {
      "empty": {
        "campaign": "Keine Kampagne erstellt.",
        "company": "Kein Unternehmen verfügbar.",
        "device": "Kein Gerät verfügbar.",
        "device-group": "Keine Gerätegruppe erstellt.",
        "license": "Keine Lizenzen verfügbar.",
        "no.add.permission.user-guide": "Sie haben nicht die Berechtigung, um eine/einen neuen { type } anzulegen. Bitte wenden Sie sich an Ihren Administrator für Unterstützung.",
        "tenant-directory": "Es wurde noch kein Mieterverzeichnis erstellt.",
        "user": "Keine Nutzer in dieser Kategorie.",
        "user-guide": "Klicken Sie auf das Pluszeichen (+) in der oberen linken Ecke der Spalte, um neue hinzuzufügen."
      }
    },
    "loading": {
      "assets": "Assets werden geladen.",
      "campaigns": "Kampagnen werden geladen.",
      "capacities": "Rollen werden geladen.",
      "devices": "Geräte geladen.",
      "medium.assignments": "Laden von Medienzuordnungen.",
      "members": "Mitglieder werden geladen.",
      "tenant-directories": "Laden von Mieterverzeichnissen.",
      "users": "Nutzer werden geladen."
    },
    "login": {
      "button": "Einloggen",
      "button-user-edit": "Speichern Nutzerprofil",
      "button-user-init": "Admin Nutzer anlegen",
      "button-user-reset": "Send",
      "email": "E-Mail",
      "first-name": "Vorname",
      "last-name": "Nachname",
      "link-password-reset": "Passwort zurücksetzen",
      "links": {
        "impressum": "Impressum",
        "privacy-policy": "Datenschutzerklärung"
      },
      "password": "Passwort",
      "repeat_password": "Passwort wiederholen",
      "session": {
        "expires": {
          "text": "Die Sitzung läuft ab. Bitte bestätigen Sie, um es zu aktualisieren.",
          "title": "Session-Timeout"
        }
      },
      "title": "Nutzeranmeldung",
      "title-user-edit": "Nutzerprofil",
      "title-user-init": "Admin Nutzer anlegen",
      "title-user-reset": "Reset user"
    },
    "manage.devices": "Geräte verwalten",
    "manage.users": "Nutzer verwalten",
    "max.files.exceeded": "Sie können keine weiteren Dateien hochladen.",
    "media": {
      "file": {
        "confirmation": "Möchten Sie wirklich das Medium mit dem Namen ''{ name }'' löschen?",
        "delete": "Medium löschen"
      },
      "folder": {
        "confirmation": "Möchten Sie wirklich das Verzeichnis mit dem Namen ''{ name }'' löschen?",
        "delete": "Verzeichnis löschen"
      },
      "in": {
        "use": "Das Medium wird von der/den folgenden verwendet:"
      }
    },
    "monday.short": "Mo",
    "nav.logout": "Ausloggen",
    "new": "Neu",
    "new.folder": "Neuer Ordner",
    "new.pdf-file": "Neues PDF",
    "new.pdf-link": "Neuer PDF-Link",
    "new.rss-feed": "Neuer RSS-Feed",
    "oem-company": {
      "count": "{count, plural,\n  =0 {kein OEM-Unternehmen}\n  one {1 OEM-Unternehmen}\n  other {{count} OEM-Unternehmen}\n}",
      "label": "OEM-Unternehmen"
    },
    "of": "von",
    "pdf": "PDF",
    "pdf-link.exists": "Ein gleichnamiger Pdf-Link existiert bereits.",
    "placeholder": {
      "campaign.name": "Geben Sie einen Kampagnennamen ein",
      "floor.name": "Geben Sie einen Etagen-Alias ein",
      "folder.name": "Ordnername",
      "layout.view.name": "Geben Sie einen Namen für die Ansicht ein",
      "notes": "Tragen Sie Zusatzinformationen ein",
      "pdf-link.url": "PDF URL",
      "rss-feed.url": "RSS-Feed-URL",
      "search": "Suchen",
      "serialNumber": "Seriennummer",
      "tenant-directory.name": "Geben einen Namen für das Mieterverzeichnis",
      "tenant.name": "Geben Sie einen Mieternamen ein"
    },
    "product-package": {
      "cancel": "Produkt kündigen",
      "canceled": "Gekündigt",
      "cancellation": "Kündigung",
      "companyUrl": "Unternehmensurl",
      "companyUrl.exists": "Unternehmen Url aexistiert bereits",
      "companyUrl.not.set": "Unternehmensurl ist nicht gesetzt",
      "count": "{count, plural,\n  =0 {Kein Produkt}\n  one {1 Produkt}\n  other {{count} Produkte}\n}",
      "description": "Beschreibung",
      "expired": "Ausgelaufen",
      "loading": "Produkt wird geladen.",
      "maxDevices": "Maximale Anzahl Geräte",
      "maxDevices.not.set": "Maximale Anzahl Geräte ist nicht gesetzt",
      "maxUsers": "Maximale Anzahl Nutzer",
      "maxUsers.not.set": "Maximale Anzahl Nutzer ist nicht gesetzt",
      "none": "kein Produktpaket zugeordnet",
      "not.in.use": "nicht verwendet",
      "remove": "Produkt löschen",
      "revokeCancellation": "Kündigung widerrufen",
      "status": "Status",
      "statusAutoExtend": "Ihr Produkt wird automatisch verlängert am",
      "statusCanceled": "Ihr Produkt wurde gekündigt am",
      "statusExpired": "Ihr Produkt ist ausgelaufen.",
      "storageLimit": "Speicherbeschränkung",
      "storageLimit.min": "Die Speicherbeschränkung muss größer sein.",
      "storageLimit.not.set": "Speicherbeschränkung ist nicht gesetzt"
    },
    "query.confirmation.media.deletion": "Möchten Sie das Medium dennoch löschen? Es wird dann aus diesen Kampagnen und/oder Mieter-Verzeichnisse entfernt.",
    "rss": "RSS",
    "saturday.short": "Sa",
    "select.capacity": "Befugnis wählen",
    "select.event": "Ereignis auswählen...",
    "serialNumber": "Seriennumer",
    "server.big.file.size": "Dateigröße zu groß für die Serverkonfiguration",
    "sunday.short": "So",
    "symbol": {
      "seconds": "(s)"
    },
    "technical.error": "Technischer Fehler",
    "tenant": {
      "confirmation": "Möchten Sie den Mieter mit dem Namen ''{ name }'' wirklich aus dem Stockwerk ''{ belongsToName }'' löschen?",
      "delete": "Mieter löschen",
      "label": "Tenant"
    },
    "tenant-directory": {
      "add": {
        "description": "Klicken Sie hier, um ein neues Mieterverzeichnis hinzuzufügen.",
        "label": "Mieterverzeichnis hinzufügen"
      },
      "count": "{count, plural,\n  =0 {Kein Mieterverzeichnis}\n  one {1 Mieterverzeichnis}\n  other {{count} Mieterverzeichnis}\n}",
      "delete": {
        "confirmation": "Möchten Sie das Mieterverzeichnis mit dem Namen ''{name}'' wirklich löschen?",
        "title": "Mieterverzeichnis löschen"
      },
      "label": "Mieterverzeichnis",
      "loading": "Mieterverzeichnis wird geladen",
      "none.selected": "Fügen Sie ein neues Mieterverzeichnis hinzu oder wählen Sie eines aus der Liste aus."
    },
    "text.no.campaign.selected": "Fügen Sie eine neue Kampagne hinzu oder wählen Sie eine aus der Liste aus.",
    "thumbnail": "Thumbnail",
    "thursday.short": "Do",
    "toggle.navigation": "Navigation ein-/ausschalten",
    "tuesday.short": "Di",
    "type": "Typ",
    "unknown.error": "Ein Fehler ist aufgetreten. Bitte wenden Sie sich an einen Administrator.",
    "unknown.server.error": "Die API hat mit 'Unbekannter Serverfehler' geantwortet.",
    "upload": {
      "avatar": {
        "preview": "Avatar zum Hochladen",
        "uploading": "Avatar wird hochgeladen"
      },
      "canceled": "Der Upload wird abgebrochen.",
      "drop.label": "<span class=\"drop-label\">Dateien <span class=\"text-orange\">hierhin</span><span> ziehen oder </span><span class=\"text-orange\">durchsuchen</span>.</span>",
      "logo": {
        "preview": "Logo zum Heraufladen",
        "uploading": "Logo wird heraufgeladen"
      }
    },
    "url.not.pdf": "Die URL enthält keine PDF-Datei.",
    "used.storage": "Speicherbelegung",
    "user": {
      "add": {
        "description": "Klicken Sie, um neue Nutzer einzuladen.",
        "invite": "Nutzer einladen",
        "label": "Nutzer hinzufügen",
        "placeholder": "Email-Adresse eintragen"
      },
      "avatar": {
        "image": "Avatar Bild",
        "not.set": "Kein Benutzeravatar hochgeladen"
      },
      "companyAdmin": {
        "count": "{count, plural,\n  =0 {Keine Administratoren}\n  one {1 Administrator}\n  other {{count} Administratoren}\n}"
      },
      "companyContentManager": {
        "count": "{count, plural,\n  =0 {Kein Content Manager}\n  one {1 Content Manager}\n  other {{count} Content Manager}\n}"
      },
      "companyRemoveUser": "Nutzer entfernen",
      "companyRemoveUserConfirmation": "Möchten Sie tatsächlich den Nutzer mit der Email ''{ email }'' entfernen?",
      "reset.password": {
        "fail": "Für die angegebene E-Mail wurde kein Benutzer gefunden.",
        "success": "Eine zurückgesetzte E-Mail wurde an das angegebene E-Mail-Konto gesendet. Bitte folgen Sie den Anweisungen."
      }
    },
    "userRoleNames": {
      "admin": "Admin",
      "clientAdmin": "Mandanten Admin",
      "contentManager": "Inhaltsverwalter",
      "oemAdmin": "OEM Admin"
    },
    "wednesday.short": "Mi"
  }], ["en", {
    "Acknowledgement received": "Acknowledgement received",
    "Active signal": "Active signal",
    "Campaigns": "Campaigns",
    "Companies": "Companies",
    "Devices": "Devices",
    "Door Number": "Door Number",
    "Door Status": "Door Status",
    "Floor": "Floor",
    "Groups": "Groups",
    "Licenses": "Licenses",
    "MB": "MB",
    "Media": "Media",
    "Status": "Status",
    "This value is already used.": "This value is already used.",
    "Type": "Type",
    "Users": "Users",
    "acknowledgement.received": "Acknowledgement Received",
    "act.as.admin": "Act as admin",
    "add.campaign": "Add campaign",
    "api.unreachable": "The API is unreachable.",
    "area": {
      "bottom": "Bereich bottom",
      "center": "Area middle",
      "left": "Area left",
      "main": "Main area",
      "right": "Area right",
      "top": "Bereich top"
    },
    "asset": {
      "confirmation": "Do you really want to delete the medium with the name ''{ name }'' from the campaign ''{ campaignName }''?"
    },
    "associated.lifts": "Associated lifts",
    "bad.credentials": "Username and/or password are invalid.",
    "blocked": {
      "contactButton": "Contact Sales Department",
      "explanation": "Your priviledges are revoked, because your registration has expired. For further information please refer to our sale department",
      "title": "Product Package Expired"
    },
    "button": {
      "add": "Add",
      "apply.changes": "Apply Changes",
      "save": "Save"
    },
    "campaign": {
      "add": {
        "description": "Click here to add new campaigns.",
        "label": "Add Campaign"
      },
      "associated": "Campaigns for ''{ name }''",
      "count": "{count, plural,\n  =0 {No campaign assigned}\n  one {1 assigned campaign}\n  other {{count} associated campaigns}\n}",
      "delete": {
        "confirmation": "Do you really want to delete the campaign with the name ''{ name }''?",
        "title": "Delete campaign"
      },
      "dirty": {
        "description": "Campaign has unsaved changes. Do you really want to leave the page?",
        "title": "Unsaved Campaign"
      },
      "landing": {
        "explanation": "Organise your advertisement campaigns and assign them to your flexy<span class=\"flexy-P\">P</span>age devices.",
        "loading": "flexy<span class=\"flexy-P\">P</span>age Campaign content is loading.",
        "welcome": "Welcome to flexy<span class=\"flexy-P\">P</span>age Campaign"
      },
      "loading": "Loading campaign",
      "play.preview": "Preview"
    },
    "campaign.logo": "flexyPage Campaign Logo",
    "campaigns": {
      "edit": {
        "description": "Click here to modify the campaigns.",
        "label": "Modify Campaigns"
      }
    },
    "capacity.notAvailable": "You do not have any assigned role. Please, contact an administrator.",
    "client-company": {
      "count": "{ count, plural,\n  =0 {No Client Company}\n  one {1 Client Company}\n  other {{ count } Client Companies}\n  }",
      "label": "Client Companies"
    },
    "company": {
      "add": {
        "description": "Click here to add new companies.",
        "label": "Add Company"
      },
      "admin": {
        "confirmation": "Do you really want to delete the admin with the Name ''{ name }'' from this company?",
        "delete": "Remove admin from company"
      },
      "confirmation": "Do you really want to delete the company with the name ''{ name }''?",
      "count": "{ count, plural,\n  =0 {No Companies}\n  one {1 Company}\n  other {{count} Companies}\n}",
      "create": "Create new company",
      "data": {
        "address": "Company address",
        "customerId": "unique company ID",
        "name": "Name for the company"
      },
      "delete": "Delete company",
      "logo": {
        "label": "Company logo",
        "not.set": "No logo uploaded"
      },
      "none-selected": "Manage your companies, add new ones and edit existing ones by selecting them from the list.",
      "not.set": "No Company ID assigned",
      "single": "Company"
    },
    "create.campaign": "Create Campaign",
    "create.new.campaign": "Click to create a new campaign.",
    "device": {
      "add": {
        "description": "Click here to add new devices.",
        "label": "Add Device",
        "tenant-directory": "Add a tenant directory to this device."
      },
      "addCampaigns": "Add campaigns to this device.",
      "confirmation": "Do you really want to delete the device with the ''{ name }''?",
      "count": "{ count, plural,\n  =0 {No Device}\n  one {1 Device}\n  other {{count} Devices}\n}",
      "create": {
        "fail": "Device creation failed. Please, contact an Administrator."
      },
      "delete": "Delete device",
      "manage": {
        "description": "Click here to manage your devices."
      },
      "none-selected": "Add a new device or select one from the list."
    },
    "device-group": {
      "addCampaigns": "Add campaigns to this device group",
      "addDevices": "Add devices to this device group",
      "addMembers": "Add members to this device group",
      "confirmation": "Do you really want to delete the device group with the name ''{ name }''?",
      "create": "Create device group",
      "delete": "Delete device group",
      "label": "Device Groups",
      "members": "Members",
      "name": {
        "placeholder": "Name for device group"
      },
      "none-selected": "Add a new device group or select one from the list."
    },
    "dialog": {
      "text": {
        "change.layout": "Do you really want to change the layout? The entries can be lost"
      },
      "title": {
        "change.layout": "Change layout",
        "create.layout.view": "Create new view",
        "folder.media.in.use": "Media in the folder are in use",
        "media.in.use": "Media is in use",
        "start.campaign": "Start new campaign"
      }
    },
    "duration": {
      "hours": "hours",
      "minutes": "minutes",
      "seconds": "seconds"
    },
    "effective": "Actually used storage",
    "error": {
      "assetOnCampaignStorageLimit": "The media cannot be added. Maximum storage limit will be exceeded on one of the devices.",
      "campaignOnDeviceStorageLimit": "The campaign cannot be added. Maximum storage limit will be exceeded.",
      "company.name.exists": "A company with the same name already exists.",
      "customer.id.exists": "This customer ID already exists.",
      "device.has.license": "This device has already a license.",
      "devicesMaxCap": "No additional devices can be added.",
      "email.exists": "This email already exists.",
      "existingDevice": "Device ''{ deviceSerialNumber }'' is already associated with company ''{ companyName }''.",
      "folder.file.exists": "The file already exists in this folder.",
      "invalidEmail": "The given email address is not valid.",
      "license.key.exists": "This license key already exists.",
      "name.not_blank": "The name input field cannot be empty.",
      "not.found": "Error: Not Found",
      "not.serialNumber": "This is not a serial number.",
      "occurred": "An error occurred",
      "only.image": "Only image is allowed.",
      "password": {
        "mismatch": "The password must match.",
        "no.minimum.character.length": "The password has to contain at least 8 characters.",
        "no.minimum.character.types": "The password has to contain at least 3 of the 4:\nnumbers, special characters, upper or lower case letters.",
        "not_blank": "The password input field cannot be empty."
      },
      "rss-feed.exists": "An RSS Feed with the same URL already exists.",
      "server.zero.status": "An error occurred during upload. Possibly the file was larger than is currently allowed.",
      "storageLimit": "The file cannot be uploaded. Maximum storage limit will be exceeded.",
      "tenant-directory.not.exists": "Requested tenant directory does not exist.",
      "too.many.login.attempts": "Too many login attempts",
      "updating.user": "Error during update of user.",
      "url.not.found": "The requested URL ''{ url }'' is not found.",
      "user": {
        "email.not_blank": "The email input field cannot be empty."
      },
      "usersMaxCap": "No additional users can be invited.",
      "value.not_blank": "This value should not be blank."
    },
    "explanatory": {
      "text": {
        "scheduled.appearance": "These settings steer the appearance of this layout based on <b>time restraints</b>.",
        "triggered.appearance": "These settings steer the appearance ot this layout based on <b>elevator events</b>."
      }
    },
    "files.to.upload": "Files to upload",
    "floor": {
      "auto-name": "Floor",
      "confirmation": "Do you really want to delete the floor with the name ''{ name }'' from the tenant directory ''{belongsToName}''?",
      "count": "{ count, plural,\n  =0 {No floors}\n  one {1 floor}\n  other {{ count } floors}\n}",
      "delete": "Delete floor",
      "label": "Floor Alias"
    },
    "folder.empty": "Media folder is empty.",
    "folder.exists": "A folder with the same name already exists.",
    "friday.short": "Fri",
    "heading": {
      "event.triggered.appearance": "Event triggered appearance",
      "scheduled.appearance": "Scheduled appearance"
    },
    "invalid.file.type": "File type is not supported.",
    "label": {
      "address": "Address",
      "adminUsers": "Admin Users",
      "admins": "Admins",
      "campaign": "Campaign",
      "campaign.name": "Campaign Name",
      "campaigns": "Campaigns",
      "cancel": "Cancel",
      "companies": "Companies",
      "company": "Company",
      "confirm": "Confirm",
      "content": "Content",
      "contentManagers": "Content Managers",
      "create": {
        "campaign": "Create Campaign",
        "device": "Create new device",
        "floor": "Create new floor",
        "folder": "Create Folder",
        "layout.view": "Create view",
        "pdf-link": "Create new PDF link",
        "rss-feed": "Create new RSS Feed",
        "tenant": "Create new tenant",
        "tenant-directory": "Create new tenant directory"
      },
      "date": {
        "end": "End date",
        "start": "Start date"
      },
      "days": "Days",
      "device": "Device",
      "device-group.campaigns": "Device groups associated campaigns",
      "device-groups": "Device Groups",
      "devices": "Devices",
      "display.duration": "Display duration",
      "duration": "Duration",
      "edit": "Edit",
      "email": "Email",
      "emptySearchResults": "No results found for ''{ search }''.",
      "event": "Event",
      "firstName": "First Name",
      "history": "History",
      "lastName": "Last Name",
      "license": {
        "event": {
          "associated-to-device": "License associated to device.",
          "created": "License created.",
          "dissociated-from-device": "License dissociated from device.",
          "updated-valid-until": "Extended validity."
        },
        "many": "Licenses",
        "one": "License"
      },
      "missingAddress": "No address specified.",
      "name": "Name",
      "noAdminUsers": "No Admin Users",
      "notes": "Notes",
      "order.licenses": "Order licenses",
      "override.displayDuration": "Override default duration",
      "password": "Password",
      "passwordConfirm": "Confirm Password",
      "product-package": {
        "companyUrl": "Company Url",
        "settings": "Product Package Settings"
      },
      "save": "Save",
      "selectCapacity": "Please choose your capacity",
      "selectCompany": "Please choose your company",
      "serialNumber": "Serial Number",
      "tenant-directories": "Tenant Directories",
      "tenants.per.floor": "Tenants per floor",
      "time": {
        "end": "Time end",
        "start": "Time start"
      },
      "users": "Users",
      "validUntil": "Valid Until"
    },
    "last.online": "Last Online",
    "layoutView": {
      "confirmation": "Do you really want to delete theconfirmation layout view with the name ''{ name }'' from the campaign ''{ campaignName }''?",
      "delete": "Delete View"
    },
    "library": {
      "campaign": {
        "empty": "Campaign library is empty.",
        "heading": "Campaign Library",
        "loading": "Loading campaigns"
      },
      "device": {
        "empty": "Device library is empty.",
        "heading": "Device Library",
        "loading": "Loading devices"
      },
      "folder": {
        "add-media": "Add images within the Drag and Drop section and they'll appear here.",
        "empty": "The folder ''{ name }'' is empty."
      },
      "license": {
        "empty": "License library is empty.",
        "heading": "License Library",
        "loading": "Loading license"
      },
      "media": {
        "empty": "Add images to your media library and they'll appear here.",
        "heading": "Media library",
        "loading": "Media library is loading."
      },
      "members": {
        "empty": "There are currently no content managers at your company.",
        "heading": "Content Managers",
        "loading": "Loading users"
      },
      "tenant-directory": {
        "empty": "Tenant directory library is empty.",
        "heading": "Tenant directory library",
        "loading": "Loading tenant directories"
      }
    },
    "license": {
      "confirmation": "Do you really want to delete the license ''{key}'' for the device ''{serialNumber}''?",
      "count": "{ count, plural,\n  =0 {No Licenses}\n  one {1 License}\n  other {{count} Licenses}\n}",
      "expired": "expired",
      "none": "none assigned",
      "not.in.use": "unused",
      "remove": "Remove license from device"
    },
    "list": {
      "empty": {
        "campaign": "No campaign created yet.",
        "company": "No companies available.",
        "device": "No devices available.",
        "device-group": "No device group created yet.",
        "license": "No licenses available.",
        "no.add.permission.user-guide": "You do not have the permission to create a new { type }. Please consult the administrator for support.",
        "tenant-directory": "No tenant directory has been created yet.",
        "user": "No user in this category.",
        "user-guide": "Click the plus (+) sign on the top left corner of the column to add one."
      }
    },
    "loading": {
      "assets": "Loading assets.",
      "campaigns": "Loading campaigns.",
      "capacities": "Loading capacities.",
      "devices": "Loading devices.",
      "medium.assignments": "Loading medium assignments.",
      "members": "Loading members list.",
      "tenant-directories": "Loading tenant directories.",
      "users": "Loading users."
    },
    "login": {
      "button": "Login",
      "button-user-edit": "Save user profile",
      "button-user-init": "Create admin user",
      "button-user-reset": "Send",
      "email": "Email",
      "first-name": "First name",
      "last-name": "Last name",
      "link-password-reset": "Reset password",
      "links": {
        "impressum": "Legal Notice",
        "privacy-policy": "Privacy Policy"
      },
      "password": "Password",
      "repeat_password": "Repeat password",
      "session": {
        "expires": {
          "text": "The session is about to expired. Please confirm to refresh the session.",
          "title": "Session timed out"
        }
      },
      "title": "User login",
      "title-user-edit": "User profile",
      "title-user-init": "Admin user creation",
      "title-user-reset": "Reset user"
    },
    "manage.devices": "Manage Devices",
    "manage.users": "Manage Users",
    "max.files.exceeded": "You can not upload any more files.",
    "media": {
      "file": {
        "confirmation": "Do you really want the delete the media ''{name}''?",
        "delete": "Delete Medium"
      },
      "folder": {
        "confirmation": "Do you really want to delete the folder ''{name}''?",
        "delete": "Delete Folder"
      },
      "in": {
        "use": "The medium is in use by the following:"
      }
    },
    "monday.short": "Mon",
    "nav.logout": "Logout",
    "new": "New",
    "new.folder": "New Folder",
    "new.pdf-file": "New PDF file",
    "new.pdf-link": "New PDF link",
    "new.rss-feed": "New RSS Feed",
    "oem-company": {
      "count": "{ count, plural,\n   =0 {No OEM Company}\n   one {1 OEM Company}\n   other {{count} OEM Companies}\n }",
      "label": "OEM Companies"
    },
    "of": "of",
    "pdf": "PDF",
    "pdf-link.exists": "A PDF link with the same name already exists.",
    "placeholder": {
      "campaign.name": "Enter a campaign name",
      "floor.name": "Enter a floor name",
      "folder.name": "Folder name",
      "layout.view.name": "Enter a name for the view",
      "notes": "Notes",
      "pdf-link.url": "PDF URL",
      "rss-feed.url": "RSS Feed URL",
      "search": "Search",
      "serialNumber": "Serial number",
      "tenant-directory.name": "Enter a tenant directory name",
      "tenant.name": "Enter a tenant name"
    },
    "product-package": {
      "cancel": "Cancel Product",
      "canceled": "Canceled",
      "cancellation": "Cancellation",
      "companyUrl": "Company Url",
      "companyUrl.exists": "Company Url already exists",
      "companyUrl.not.set": "Company Url is not set",
      "count": "{count, plural,\n  =0 {No Product Packages}\n  one {1 Product Package}\n  other {{count} Product Packages}\n}",
      "description": "Description",
      "expired": "Expired",
      "loading": "Product details are loading.",
      "maxDevices": "Maximum Devices",
      "maxDevices.not.set": "Maximum number of devices is not set",
      "maxUsers": "Maximum Users",
      "maxUsers.not.set": "Maximum number of users is not set",
      "none": "none assigned",
      "not.in.use": "unused",
      "remove": "Remove Product",
      "revokeCancellation": "Revoke Cancellation",
      "status": "Status",
      "statusAutoExtend": "The product will be automatically extended on",
      "statusCanceled": "The product has been canceled on",
      "statusExpired": "The product has expired.",
      "storageLimit": "Storage Limit",
      "storageLimit.min": "Storage limit must be higher",
      "storageLimit.not.set": "Storage limit is not set"
    },
    "query.confirmation.media.deletion": "Do you want to delete the media nontheless? Then, it will be removed from these campaigns and/or tenant directories.",
    "rss": "RSS",
    "saturday.short": "Sat",
    "select.capacity": "Select Capacity",
    "select.event": "Select event...",
    "serialNumber": "Serial number",
    "server.big.file.size": "File size to big for server configuration.",
    "sunday.short": "Sun",
    "symbol": {
      "seconds": "(sec.)"
    },
    "technical.error": "Technical Error",
    "tenant": {
      "confirmation": "Do you really want to delete the tenant with the name ''{ name }'' from the floor ''{belongsToName}''?",
      "delete": "Delete tenant",
      "label": "Tenant"
    },
    "tenant-directory": {
      "add": {
        "description": "Click here to add new tenant directories.",
        "label": "Add Tenant Directory"
      },
      "count": "{count, plural,\n  =0 {No tenant directories}\n  one {1 tenant directory}\n  other {{count} tenant directories}\n}",
      "delete": {
        "confirmation": "Do you really want to delete the tenant directory with the name ''{ name }''?",
        "title": "Delete tenant directory"
      },
      "label": "Tenant Directory",
      "loading": "Loading tenant directory",
      "none.selected": "Add a new tenant directory or select one from the list."
    },
    "text.no.campaign.selected": "Add a new campaign or select one from the list.",
    "thumbnail": "Thumbnail",
    "thursday.short": "Thu",
    "toggle.navigation": "Toggle navigation",
    "tuesday.short": "Tue",
    "type": "Type",
    "unknown.error": "An error occurred. Please, contact an Administrator.",
    "unknown.server.error": "The API responded with an 'Unknown server error'.",
    "upload": {
      "avatar": {
        "preview": "Avatar to be uploaded",
        "uploading": "Avatar is being uploaded"
      },
      "canceled": "The upload is canceled.",
      "drop.label": "<span class=\"drop-label\">Drop files <span class=\"text-orange\">here</span><span> or </span><span class=\"text-orange\">browse</span>.</span>",
      "logo": {
        "preview": "Logo to be uploaded",
        "uploading": "Logo is being uploaded"
      }
    },
    "url.not.pdf": "The URL does not contain a pdf file.",
    "used.storage": "Used Storage",
    "user": {
      "add": {
        "description": "Click here to invite new users.",
        "invite": "Invite user",
        "label": "Add User",
        "placeholder": "Enter user email"
      },
      "avatar": {
        "image": "User avatar image",
        "not.set": "No user avatar uploaded"
      },
      "companyAdmin": {
        "count": "{count, plural,\n  =0 {No Admins}\n  one {1 Admin User}\n  other {{count} Admin Users}\n}"
      },
      "companyContentManager": {
        "count": "{count, plural,\n    =0 {No Content Managers}\n    one {1 Content Manager}\n    other {{count} Content Managers}\n}"
      },
      "companyRemoveUser": "Remove user",
      "companyRemoveUserConfirmation": "Do you really want to delete the user with the email address ''{ email }''\n entfernen?",
      "reset.password": {
        "fail": "No user found for the given email.",
        "success": "A reset email has been sent to the given email account. Please, follow the instructions."
      }
    },
    "userRoleNames": {
      "admin": "Admin",
      "clientAdmin": "Client Admin",
      "contentManager": "Content Manager",
      "oemAdmin": "OEM Admin"
    },
    "wednesday.short": "Wed"
  }], ["pt", {
    "Acknowledgement received": "Receber confirmação",
    "Active signal": "Sinal pendente",
    "Campaigns": "Campanhas",
    "Companies": "Empresa",
    "Devices": "Dispositivos",
    "Door Number": "Número de porta",
    "Door Status": "Status da porta",
    "Floor": "Andar",
    "Groups": "Grupos",
    "Licenses": "Licenças",
    "MB": "MB",
    "Media": "Meios",
    "Status": "Estado",
    "This value is already used.": "Este valor já está a ser usado.",
    "Type": "Tipo",
    "Users": "Utilizador",
    "acknowledgement.received": "Receber confirmação",
    "act.as.admin": "Agir como administrador",
    "add.campaign": "Adicionar campanha",
    "api.unreachable": "A API está inacessível.",
    "area": {
      "bottom": "fundo da área",
      "center": "Área média",
      "left": "Área restante",
      "main": "Área principal",
      "right": "Área direita",
      "top": "topo da área"
    },
    "asset": {
      "confirmation": "Quer realmente eliminar o meio com o nome ''{ name }'' da campanha ''{ campaignName }''?"
    },
    "associated.lifts": "Elevadores atribuídos",
    "bad.credentials": "Nome de usuário e/ou senha são inválidos.",
    "blocked": {
      "contactButton": "Entre em contato com o departamento de vendas",
      "explanation": "Your priviledges are revoked, because your registration has expired. For further information please refer to our sale department",
      "title": "Product Package Expired"
    },
    "button": {
      "add": "Adicionar",
      "apply.changes": "Aceitar alterações",
      "save": "Guardar"
    },
    "campaign": {
      "add": {
        "description": "Clique para adicionar novas campanhas.",
        "label": "Adicionar campanha"
      },
      "associated": "Campanhas para ''{ name }''",
      "count": "{count, plural,\n  =0 {Nenhuma campanha atribuída}\n  one {1 Campanha atribuída}\n  other {{count} Campanhas atribuídas}\n}",
      "delete": {
        "confirmation": "Quer realmente eliminar a campanha com o nome ''{ name }''?",
        "title": "Eliminar campanha"
      },
      "dirty": {
        "description": "A campanha tem alterações não salvas. Tem certeza de que deseja sair da página?",
        "title": "Campanha não salva"
      },
      "landing": {
        "explanation": "Organize as suas campanhas publicitárias e atribua-as aos seus dispositivos finais flexy<span class=\"flexy-P\">P</span>age.",
        "loading": "Os conteúdos da campanha flexy<span class=\"flexy-P\">P</span>age estão a ser carregados.",
        "welcome": "Bem-vindo à campanha flexy<span class=\"flexy-P\">P</span>age"
      },
      "loading": "Campanha está a ser carregada.",
      "play.preview": "Visualizar"
    },
    "campaign.logo": "flexyPage Campaign Logotipo",
    "campaigns": {
      "edit": {
        "description": "Clique aqui para editar as suas campanhas.",
        "label": "Editar campanhas"
      }
    },
    "capacity.notAvailable": "Não tem nenhum cargo atribuído. Contacte um administrador.",
    "client-company": {
      "count": "{count, plural,\n  =0 {Nenhumas empresas de clientes}\n  one {1 Empresa de clientes}\n  other {{count} Empresas de clientes}\n}",
      "label": "Empresas de clientes"
    },
    "company": {
      "add": {
        "description": "Clique para adicionar novas empresas.",
        "label": "Adicionar empresa"
      },
      "admin": {
        "confirmation": "Quer realmente remover o Admin com o nome ''{ name }'' desta empresa?",
        "delete": "Remover Admin da empresa"
      },
      "confirmation": "Quer realmente eliminar a empresa com o nome ''{ name }''?",
      "count": "{count, plural,\n  =0 {Nenhuma empresa}\n  one {1 Empresa}\n  other {{count} Empresa}\n}",
      "create": "Criar nova empresa",
      "data": {
        "address": "Endereço da empresa",
        "customerId": "ID da empresa",
        "name": "Nome para a empresa"
      },
      "delete": "Eliminar empresa",
      "logo": {
        "label": "Logo da empresa",
        "not.set": "Nenhum logo de empresa carregado"
      },
      "none-selected": "Faça aqui a gestão das suas empresas, insira novas ou edite as existentes.",
      "not.set": "Nenhum ID de empresa atribuído",
      "single": "Company"
    },
    "create.campaign": "Criar campanha",
    "create.new.campaign": "Clique para criar uma nova campanha.",
    "device": {
      "add": {
        "description": "Clique para adicionar novos dispositivos.",
        "label": "Adicionar dispositivo",
        "tenant-directory": "Adicione um diretório de inquilino a este dispositivo."
      },
      "addCampaigns": "Adicionar campanhas a este dispositivo.",
      "confirmation": "Quer realmente eliminar o dispositivo com o nome ''{ name }''?",
      "count": "{count, plural,\n  =0 {Nenhum dispositivo}\n  one {1 Dispositivo}\n  other {{count} Dispositivos}\n}",
      "create": {
        "fail": "A criação do dispositivo falhou. Contacte um administrador."
      },
      "delete": "Eliminar dispositivo",
      "manage": {
        "description": "Click here to manage your devices."
      },
      "none-selected": "Adicione um novo dispositivo ou escolha um da lista."
    },
    "device-group": {
      "addCampaigns": "Adicione campanhas a este grupo de dispositivos.",
      "addDevices": "Adicione dispositivos a este grupo de dispositivos.",
      "addMembers": "Adicione um gestor de conteúdos a este grupo de dispositivos.",
      "confirmation": "Quer realmente eliminar o grupo de dispositivos com o nome ''{ name }''?",
      "create": "Criar grupo de dispositivos",
      "delete": "Eliminar grupo de dispositivos",
      "label": "Grupos de dispositivos",
      "members": "Gestor de conteúdos",
      "name": {
        "placeholder": "Nome do grupo de dispositivos"
      },
      "none-selected": "Adicione um novo grupo de dispositivos ou escolha um da lista."
    },
    "dialog": {
      "text": {
        "change.layout": "Você realmente quer mudar o layout? As entradas podem ser perdidas"
      },
      "title": {
        "change.layout": "Alterar layout",
        "create.layout.view": "Criar nova vista",
        "folder.media.in.use": "Os meios nesta pasta estão a ser usados",
        "media.in.use": "O meio está a ser usado",
        "start.campaign": "Iniciar uma nova campanha"
      }
    },
    "duration": {
      "hours": "horas",
      "minutes": "minutos",
      "seconds": "segundos"
    },
    "effective": "Armazenamento usado de fato",
    "error": {
      "assetOnCampaignStorageLimit": "Não é possível adicionar a imagem/vídeo. O limite máximo da memória para um dos dispositivos foi excedido.",
      "campaignOnDeviceStorageLimit": "Não é possível carregar a campanha. Está a exceder o limite da memória.",
      "company.name.exists": "Uma empresa com o mesmo nome já existe.",
      "customer.id.exists": "Este ID de cliente já existe.",
      "device.has.license": "Este dispositivo já possui uma licença.",
      "devicesMaxCap": "Não podem ser adicionados mais dispositivos.",
      "email.exists": "Esse email já existe.",
      "existingDevice": "O dispositivo ''{ deviceSerialNumber }'' já está associado com a empresa ''{ companyName }''.",
      "folder.file.exists": "O arquivo já existe nesta pasta.",
      "invalidEmail": "O endereço de e-mail indicado não é válido.",
      "license.key.exists": "Já existe uma empresa com o mesmo nome.",
      "name.not_blank": "O campo de entrada do nome não pode estar vazio.",
      "not.found": "Error: Not Found",
      "not.serialNumber": "Isto não é um n.º de série.",
      "occurred": "Um erro ocorreu",
      "only.image": "Apenas imagem é permitida.",
      "password": {
        "mismatch": "Os campos de senha devem corresponder.",
        "no.minimum.character.length": "A senha deve conter pelo menos 8 caracteres.",
        "no.minimum.character.types": "A senha deve conter pelo menos 3 dos 4: números, caracteres especiais, letras maiúsculas ou minúsculas.",
        "not_blank": "O campo de entrada de senha não pode estar vazio."
      },
      "rss-feed.exists": "Já existe um RSS Feed com o mesmo URL.",
      "server.zero.status": "Ocorreu um erro durante o upload. Possivelmente, o arquivo era maior do que o permitido atualmente.",
      "storageLimit": "Não é possível carregar o ficheiro. Está a exceder o limite da memória.",
      "tenant-directory.not.exists": "O diretório de inquilinos solicitado não existe.",
      "too.many.login.attempts": "Demasiadas tentativas de login",
      "updating.user": "Erro ao atualizar o utilizador.",
      "url.not.found": "O URL solicitado ''{ url }'' não foi encontrado.",
      "user": {
        "email.not_blank": "O campo de entrada do e-mail não pode estar vazio."
      },
      "usersMaxCap": "Não podem ser convidados mais utilizadores.",
      "value.not_blank": "Este valor não pode estar vazio."
    },
    "explanatory": {
      "text": {
        "scheduled.appearance": "Proceda a definições para apresentar este layout <b>controlado por tempo</b>.",
        "triggered.appearance": "Proceda a definições para apresentar este layout <b>controlado pelo estado de entrega</b>."
      }
    },
    "files.to.upload": "Ficheiros restantes",
    "floor": {
      "auto-name": "Andar",
      "confirmation": "Tem certeza de que deseja excluir o andar com o nome ''{ name }'' do diretório de inquilinos ''{ belongsToName }''?",
      "count": "{ count, plural,\n  =0 {Sem pisos}\n  one {1 piso}\n  other {{ count } pisos}\n}",
      "delete": "Excluir andar",
      "label": "Alias ​​do andar"
    },
    "folder.empty": "Diretório de meios está vazio.",
    "folder.exists": "A pasta já existe.",
    "friday.short": "Sex",
    "heading": {
      "event.triggered.appearance": "Apresentação controlada por evento",
      "scheduled.appearance": "Apresentação controlada por tempo"
    },
    "invalid.file.type": "Tipo de ficheiro não é suportado.",
    "label": {
      "address": "Endereço",
      "adminUsers": "Admin utilizadores",
      "admins": "Administradores",
      "campaign": "Campanha",
      "campaign.name": "Nome da campanha",
      "campaigns": "Campanhas",
      "cancel": "Cancelar",
      "companies": "Empresa",
      "company": "Empresa",
      "confirm": "Confirmar",
      "content": "Conteúdos",
      "contentManagers": "Gestor de conteúdos",
      "create": {
        "campaign": "Nova campanha",
        "device": "Criar novo dispositivo",
        "floor": "Criar novo andar",
        "folder": "Criar nova pasta",
        "layout.view": "Criar vista",
        "pdf-link": "Criar novo link para PDF",
        "rss-feed": "Crie um novo feed RSS",
        "tenant": "Criar novo inquilino",
        "tenant-directory": "Crie um novo diretório de inquilinos"
      },
      "date": {
        "end": "Data final",
        "start": "Data inicial"
      },
      "days": "Dias",
      "device": "Dispositivo",
      "device-group.campaigns": "Campanhas associadas a grupos de dispositivos",
      "device-groups": "Grupos de dispositivos",
      "devices": "Dispositivos",
      "display.duration": "Duração da apresentação",
      "duration": "Duração",
      "edit": "Editar",
      "email": "E-mail",
      "emptySearchResults": "Nenhum resultado encontrado para ''{ search }''?",
      "event": "Ocorrência",
      "firstName": "Nome próprio",
      "history": "Histórico",
      "lastName": "Apelido",
      "license": {
        "event": {
          "associated-to-device": "Licença atribuída a dispositivo.",
          "created": "Licença criada.",
          "dissociated-from-device": "Licença removida do dispositivo",
          "updated-valid-until": "Validade prolongada."
        },
        "many": "Licenças",
        "one": "Licença"
      },
      "missingAddress": "Nenhum endereço atribuído.",
      "name": "Nome",
      "noAdminUsers": "Nenhum Admin utilizadores",
      "notes": "Notas",
      "order.licenses": "Encomenda licenças",
      "override.displayDuration": "Definir manualmente a duração da apresentação",
      "password": "Palavra-passe",
      "passwordConfirm": "Confirmar palavra-passe",
      "product-package": {
        "companyUrl": "Url da empresa",
        "settings": "Definições do produto"
      },
      "save": "Guardar",
      "selectCapacity": "Selecione uma função",
      "selectCompany": "Selecione uma empresa",
      "serialNumber": "Número de série",
      "tenant-directories": "Diretórios de inquilinos",
      "tenants.per.floor": "Inquilinos por andar",
      "time": {
        "end": "Hora final",
        "start": "Hora inicial"
      },
      "users": "Utilizador",
      "validUntil": "Válido até"
    },
    "last.online": "Última vez online",
    "layoutView": {
      "confirmation": "Quer realmente eliminar a vista de layout com o nome ''{ name }'' da campanha ''{ campaignName }''?",
      "delete": "Eliminar vista de layout"
    },
    "library": {
      "campaign": {
        "empty": "A biblioteca de campanhas está vazia.",
        "heading": "Biblioteca de campanhas",
        "loading": "Estão a ser carregadas campanhas"
      },
      "device": {
        "empty": "A biblioteca de dispositivos está vazia.",
        "heading": "Biblioteca de dispositivos",
        "loading": "Os dispositivos estão a ser carregados."
      },
      "folder": {
        "add-media": "Adicione imagens através da área Drag'n'Drop para serem aqui apresentadas.",
        "empty": "A pasta ''{ name }'' está vazia."
      },
      "license": {
        "empty": "A biblioteca de licenças está vazia.",
        "heading": "Biblioteca de licenças",
        "loading": "As licenças estão a ser carregadas."
      },
      "media": {
        "empty": "Adicione imagens à sua biblioteca de meios para serem aqui apresentadas.",
        "heading": "Biblioteca de meios",
        "loading": "A biblioteca de meios está a se carregada."
      },
      "members": {
        "empty": "Atualmente não há gestores de conteúdos na sua empresa.",
        "heading": "Gestor de conteúdos",
        "loading": "Os utilizadores estão a ser carregados."
      },
      "tenant-directory": {
        "empty": "A biblioteca do diretório do locatário está vazia.",
        "heading": "Biblioteca do diretório do locatário",
        "loading": "Os diretórios do locatário estão carregando."
      }
    },
    "license": {
      "confirmation": "Quer realmente remover a licença ''{ key }'' do dispositivo ''{ serialNumber }''?",
      "count": "{count, plural,\n  =0 {Nenhumas licenças}\n  one {1 Licença}\n  other {{count} Licenças}\n}",
      "expired": "expirou",
      "none": "Nenhuma licença atribuída",
      "not.in.use": "A licença não é utilizada",
      "remove": "Remover a licença do dispositivo"
    },
    "list": {
      "empty": {
        "campaign": "Nenhuma campanha criada.",
        "company": "Nenhuma empresa disponível.",
        "device": "Nenhum dispositivo disponível.",
        "device-group": "Nenhum grupo de dispositivos criado.",
        "license": "Nenhum licença disponível.",
        "no.add.permission.user-guide": "Não tem autorização para criar um(a) novo/nova { type }. Contacte o seu administrador para apoio.",
        "tenant-directory": "Nenhum diretório de inquilinos foi criado ainda.",
        "user": "Nenhum utilizador nesta categoria.",
        "user-guide": "Clique no sinal de mais (+) no canto superior esquerdo da coluna, para adicionar novos."
      }
    },
    "loading": {
      "assets": "Estão a ser carregados recursos.",
      "campaigns": "Estão a ser carregadas campanhas.",
      "capacities": "Capacidade de carga.",
      "devices": "Carregando dispositivos.",
      "medium.assignments": "Carregando atribuições médias.",
      "members": "Carregando lista de membros.",
      "tenant-directories": "Carregando diretórios de inquilinos.",
      "users": "Carregando usuários."
    },
    "login": {
      "button": "Iniciar sessão",
      "button-user-edit": "Guardar perfil de utilizador",
      "button-user-init": "Criar Admin utilizador",
      "button-user-reset": "Enviar",
      "email": "E-mail",
      "first-name": "Nome próprio",
      "last-name": "Apelido",
      "link-password-reset": "Repor palavra-passe",
      "links": {
        "impressum": "Ficha técnica",
        "privacy-policy": "Declaração de proteção de dados"
      },
      "password": "Palavra-passe",
      "repeat_password": "Repetir palavra-passe",
      "session": {
        "expires": {
          "text": "A sessão expira. Confirme para atualizar.",
          "title": "Sessão expirada"
        }
      },
      "title": "Registo do utilizador",
      "title-user-edit": "Perfil de utilizador",
      "title-user-init": "Criar Admin utilizador",
      "title-user-reset": "Repor utilizador"
    },
    "manage.devices": "Gerir dispositivos",
    "manage.users": "Gerir utilizadores",
    "max.files.exceeded": "Você não pode enviar mais arquivos.",
    "media": {
      "file": {
        "confirmation": "Quer realmente eliminar o meio com o nome ''{ name }''?",
        "delete": "Eliminar meio"
      },
      "folder": {
        "confirmation": "Quer realmente eliminar o diretório com o nome ''{ name }''?",
        "delete": "Eliminar diretório"
      },
      "in": {
        "use": "O meio é usado pelo seguinte:"
      }
    },
    "monday.short": "Seg",
    "nav.logout": "Sair",
    "new": "Novo",
    "new.folder": "Pasta nova",
    "new.pdf-file": "Novo arquivo PDF",
    "new.pdf-link": "Novo link de PDF",
    "new.rss-feed": "Novo feed RSS",
    "oem-company": {
      "count": "{count, plural,\n  =0 {Nenhuma empresa OEM}\n  one {1 Empresa OEM}\n  other {{count} Empresa OEM}\n}",
      "label": "Empresa OEM"
    },
    "of": "de",
    "pdf": "PDF",
    "pdf-link.exists": "Já existe um link de PDF com o mesmo nome.",
    "placeholder": {
      "campaign.name": "Introduza um nome de campanha",
      "floor.name": "Insira um nome de andar",
      "folder.name": "Nome da pasta",
      "layout.view.name": "Introduza um nome para a vista",
      "notes": "Introduza informações adicionais",
      "pdf-link.url": "PDF URL",
      "rss-feed.url": "URL do feed RSS",
      "search": "Procurar",
      "serialNumber": "Número de série",
      "tenant-directory.name": "Introduza um nome de diretório de inquilino",
      "tenant.name": "Insira um nome de inquilino"
    },
    "product-package": {
      "cancel": "Rescindir projeto",
      "canceled": "Rescindiu",
      "cancellation": "Rescisão",
      "companyUrl": "Url da empresa",
      "companyUrl.exists": "O Url da empresa já existe",
      "companyUrl.not.set": "O Url da empresa não está definido",
      "count": "{count, plural,\n  =0 {Nenhum produto}\n  one {1 Produto}\n  other {{count} Produtos}\n}",
      "description": "Descrição",
      "expired": "Expirou",
      "loading": "O produto é carregado.",
      "maxDevices": "Quantidade máxima de dispositivos",
      "maxDevices.not.set": "A quantidade máxima de dispositivos não está definida",
      "maxUsers": "Quantidade máxima de utilizadores",
      "maxUsers.not.set": "A quantidade máxima de utilizadores não está definida",
      "none": "nenhum pacote de produtos atribuído",
      "not.in.use": "não utilizado",
      "remove": "Eliminar produto",
      "revokeCancellation": "Revogar rescisão",
      "status": "Estado",
      "statusAutoExtend": "O seu produto é automaticamente prolongado a",
      "statusCanceled": "O seu produto foi rescindido a",
      "statusExpired": "O seu produto expirou.",
      "storageLimit": "Limite da memória",
      "storageLimit.min": "O limite da memória tem de ser maior.",
      "storageLimit.not.set": "O limite da memória não está definido"
    },
    "query.confirmation.media.deletion": "Quer realmente eliminar o meio? Nesse caso, será removido destas campanhas e/ou diretórios de inquilinos.",
    "rss": "RSS",
    "saturday.short": "Sáb",
    "select.capacity": "Selecionar autorização",
    "select.event": "Selecionar evento...",
    "serialNumber": "N.º de série",
    "server.big.file.size": "Tamanho de ficheiro demasiado grande para a configuração do servidor",
    "sunday.short": "Dom",
    "symbol": {
      "seconds": "(s)"
    },
    "technical.error": "Erro técnico",
    "tenant": {
      "confirmation": "Tem certeza de que deseja excluir o locatário com o nome ''{ name }'' do andar ''{belongsToName}''?",
      "delete": "Excluir inquilino",
      "label": "Inquilino"
    },
    "tenant-directory": {
      "add": {
        "description": "Clique aqui para adicionar novos diretórios de inquilinos.",
        "label": "Adicionar diretório de inquilinos"
      },
      "count": "{count, plural,\n  =0 {Sem diretórios de inquilinos}\n  one {1 diretório de inquilinos}\n  other {{count} diretórios de inquilinos}\n}",
      "delete": {
        "confirmation": "Tem certeza de que deseja excluir o diretório de inquilinos com o nome ''{ name }''?",
        "title": "Excluir diretório de inquilinos"
      },
      "label": "Diretório de inquilinos",
      "loading": "Carregando diretório de inquilinos",
      "none.selected": "Adicione um novo diretório de inquilinos ou selecione um na lista."
    },
    "text.no.campaign.selected": "Adicione uma nova campanha ou escolha uma da lista.",
    "thumbnail": "Miniatura",
    "thursday.short": "Qui",
    "toggle.navigation": "Alternar de navegação",
    "tuesday.short": "Ter",
    "type": "Tipo",
    "unknown.error": "An error occurred. Please, contact an Administrator.",
    "unknown.server.error": "A API respondeu com um 'Erro de servidor desconhecido'.",
    "upload": {
      "avatar": {
        "preview": "Avatar a ser carregado",
        "uploading": "Avatar está sendo carregado"
      },
      "canceled": "O upload foi cancelado.",
      "drop.label": "<span class=\"drop-label\">Puxar ficheiros <span class=\"text-orange\">para aqui</span><span> ou </span><span class=\"text-orange\">procurar</span>. </span>",
      "logo": {
        "preview": "Logo para carregar",
        "uploading": "Logo está a ser carregado"
      }
    },
    "url.not.pdf": "O URL não contém um arquivo pdf.",
    "used.storage": "Ocupação da memória",
    "user": {
      "add": {
        "description": "Clique para convidar novos utilizadores.",
        "invite": "Convidar utilizador",
        "label": "Adicionar utilizador",
        "placeholder": "Registar endereço de e-mail"
      },
      "avatar": {
        "image": "Imagem do usuário avatar",
        "not.set": "Nenhum avatar de usuário carregado"
      },
      "companyAdmin": {
        "count": "{count, plural,\n  =0 {Nenhuns administradores}\n  one {1 Administrador}\n  other {{count} Administradores}\n}"
      },
      "companyContentManager": {
        "count": "{count, plural,\n  =0 {Nenhum gestor de conteúdos}\n  one {1 Gestor de conteúdos}\n  other {{count} Gestor de conteúdos}\n}"
      },
      "companyRemoveUser": "Remover utilizadores",
      "companyRemoveUserConfirmation": "Quer realmente remover o utilizador com o e-mail ''{ email }''?",
      "reset.password": {
        "fail": "Não foi encontrado nenhum utilizador para o e-mail indicado.",
        "success": "Foi enviado um e-mail reposto à conta de e-mail especificada. Siga as instruções."
      }
    },
    "userRoleNames": {
      "admin": "Admin",
      "clientAdmin": "Cliente Admin",
      "contentManager": "Gestor de conteúdos",
      "oemAdmin": "OEM Admin"
    },
    "wednesday.short": "Qua"
  }]];
});